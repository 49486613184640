import { render, staticRenderFns } from "./chatComponent.vue?vue&type=template&id=5c53d8e8&scoped=true"
import script from "./chatComponent.vue?vue&type=script&lang=js"
export * from "./chatComponent.vue?vue&type=script&lang=js"
import style0 from "./chatComponent.vue?vue&type=style&index=0&id=5c53d8e8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c53d8e8",
  null
  
)

export default component.exports