<template>
  <div class="chats">
    <div v-if="loadingMore" class="text-center">
      <b-spinner small />
    </div>
    <div v-else-if="loadingError" class="text-center text-danger">
      {{ $t("chat.error-messages.load-of-more-messages-failed") }}
    </div>
    <div v-if="chatData.length > 0" id="chatV2">
      <div
        v-for="(msgDayGrp, dayIndex) in formattedChatData"
        :key="`${msgDayGrp.day}-${dayIndex}`"
      >
        <div class="text-center">
          <b-badge pill variant="primary" class="mx-auto mt-2 mb-1">
            {{ $d(new Date(msgDayGrp.day), "long") }}
          </b-badge>
        </div>
        <div
          v-for="(member, index) in msgDayGrp.messages"
          :key="`${member.memberData.username}-${index}`"
        >
          <div
            class="chat"
            :class="
            !isLoggedMember(member.memberData.key)
              ? 'chat-left' :
               'chat-right'"          >
            <div class="chat-avatar">
              <user-avatar
                :user="member.memberData"
                size="32"
                variant="transparent"
                class="avatar-border-2 box-shadow-1"
              />
            </div>
            <div :class="
            !isLoggedMember(member.memberData.key)
              ? 'chat-body-left'
              : 'chat-body-right'">
              <div class="chat-content">
                <small
                  class="text-primary"
                >
                  {{ member.memberData.name }} {{ member.memberData.surname }}
                </small>
                <content-with-anchors 
                  :content="member.message"
                  class="mr-3" />
                <div class="d-flex justify-content-end">
                  <small
                    :class="'text-muted'"
                    style="margin-top: -10px;"
                    :title="getMessageDateTime(member.date)"
                  >
                    {{ member.date | moment("HH:mm") }}
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Modified from Vuexy
import moment from "moment";
import { computed } from "@vue/composition-api";
import { VBTooltip, BSpinner, BBadge } from "bootstrap-vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import ContentWithAnchors from "@core/components/string-utils/ContentWithAnchors.vue";
import UserAvatar from "@core/components/user/UserAvatar.vue";

export default {
  components: {
    UserAvatar,
    BSpinner,
    FeatherIcon,
    BBadge,
    ContentWithAnchors,
  },
  directives: { "b-tooltip": VBTooltip },
  props: {
    chatData: Array,
    loadingMore: Boolean,
    loadingError: Boolean,
  },
  setup(props) {
    const formattedChatData = computed(() => {
      const chatLog = props.chatData || [];

      const formattedChatLog = [];
      let msgDayGroup = {
        day: chatLog[0]
          ? moment(chatLog[0].date).startOf("day").toISOString()
          : undefined,
        messages: [],
      };

      chatLog.forEach((msg, index) => {
        const msgDay = moment(msg.date).startOf("day").toISOString();
        if (msgDayGroup.day !== msgDay) {
          // The day has changed, push the current day and start a new one.
          formattedChatLog.push(msgDayGroup);
          msgDayGroup = {
            day: msgDay,
            messages: [],
          };
        }
        if (
          !msgDayGroup.messages.length ||
          msgDayGroup.messages[msgDayGroup.messages.length - 1]?.memberData
            .key !== msg.memberData.key
        ) {
          // a new sender has started chatting, change the senderId and add it's first message
          msgDayGroup.messages.push({
            ...msg,
            date: msg.date,
          });
        } else {
          // The same sender is chatting
          msgDayGroup.messages.push({
            ...msg,
            date: msg.date,
          });
        }

        if (index === chatLog.length - 1) formattedChatLog.push(msgDayGroup);
      });

      return formattedChatLog;
    });

    return { formattedChatData };
  },
  created() {
    if (document.getElementById("chatV2")) {
      document.getElementById("chatV2").scrollTop =
        document.getElementById("chatV2")?.scrollHeight;
    }
  },
  methods: {
    isLoggedMember(key) {
      return this.$store.getters.loggedMember.key === key;
    },
    getMessageDateTime(timestamp) {
      return moment(timestamp).toISOString();
    }
  },
};
</script>

<style lang="scss" scoped>
.chat-content {
  position: relative;
  .chat-status-icon {
    position: absolute;
    bottom: 3px;
    right: 3px;
  }
}
.chat .b-avatar {
  color: inherit;
}
.chat-left {
  display: flex;
  flex-direction: row;
}
.chat-right {
  display: flex;
  flex-direction: row-reverse;
}
.chat-body-left{
  background-color: #f0f0f0;
  border-radius: 10px 10px 10px 0;
  padding: 10px;
  margin: 10px;
  color: #333;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
}
.chat-body-right{
  background-color: #f0f0f0;
  border-radius: 10px 10px 10px 0;
  padding: 10px;
  margin: 10px;
  color: #333;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
}
.message-container {
  display: flex;
  margin: 5px 0;
  max-width: 70%;
}
.loading-more {
  text-align: center;
}
.loading-error {
  text-align: center;
}
</style>
